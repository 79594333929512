import React from "react"

import Row from "../row"
import PhotoBlock from "./photo"

const PhotosBlock = ({ block }) => {
  const { title } = block
  const photo_blocks = block.relationships.photo_blocks

  return (
    <Row classes={["flow"]}>
      <h2 className="center">{ title }</h2>
      <div className="grid-columns narrow-columns flow-2x">
        { photo_blocks.map(photo_block => <PhotoBlock key={photo_block.title} block={photo_block} />)}
      </div>
    </Row>
  )
}

export default PhotosBlock
