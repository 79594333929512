import React from "react"

import { baseUrl } from "../../settings"

import { getParsedHtml } from "../../util/format"

import { Link } from "gatsby-plugin-react-i18next"
import ALink from "../alink"

const PhotoBlock = ({ block }) => {
  const { id, title, body, link, label } = block
  let image = null
  if(block.photo) {
    image = {
      ...block.photo,
      src: block.relationships.photo.filemime.includes("svg") ? baseUrl + block.relationships.photo.uri.url : block.relationships.photo.styles.medium
    }
  }


  return (
    <div key={ id } className="pane">
      { link && image && <Link to={ link.url } className="image-ratio image-ratio-4-3">
        <img alt="" {...image} />
        { label && <div className="image-label"><span>{ label }</span></div> }
      </Link> }

      { !link && image && <div className="image-ratio image-ratio-4-3">
        <img alt="" {...image} />
        { label && <div className="image-label"><span>{ label }</span></div> }
      </div> }

      <div className="pad-s">
      <h3>{ title }</h3>
      <div>{ getParsedHtml(body.processed) }</div>
      { link && <div className="ctas start"><ALink className="more" link={ link } /></div> }
      </div>
    </div>
  )
}

export default PhotoBlock
