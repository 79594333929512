import React from "react"

import { getParsedHtml } from "../../util/format"

import { useI18next } from "gatsby-plugin-react-i18next"
import { useSessionStorage } from "../../util/useSessionStorage"

import Select from "react-select"
import ALink from "../alink"
import HeadingUnderline from "../headingUnderline"

import What from "../../images/inline/what.svg"
import Who from "../../images/inline/who.svg"
import Where from "../../images/inline/where.svg"
import With from "../../images/inline/with.svg"



const HeaderBlock = ({ block, isHome, terms }) => {
  const { t } = useI18next()

  const { title, link, subtitle, introduction } = block

  let image
  if(block.relationships.background_image) {
    const srcSet = []
    if(block.relationships.background_image.styles.large) srcSet.push(`${block.relationships.background_image.styles.large} 1280w`)
    if(block.relationships.background_image.styles.medium) srcSet.push(`${block.relationships.background_image.styles.medium} 640w`)
    if(block.relationships.background_image.styles.small) srcSet.push(`${block.relationships.background_image.styles.small} 360w`)

    image = {
      ...block.background_image,
      src: block.relationships.background_image.styles.large,
      srcSet: srcSet.join(", ")
    }
  }

  const { allTaxonomyTermSteamSubjects, allTaxonomyTermRoles, allTaxonomyTermLocations, allTaxonomyTermEngagement } = terms

  const defaultSelectProps = { isMulti: true, blurInputOnSelect: true, isSearchable: false, className: "select-wrapper", classNamePrefix: "select" }

  const whatOptions = allTaxonomyTermSteamSubjects.nodes.map(term => { return { value: term.drupal_id, label: term.name } })
  const [whatSelected, setWhatSelected] = useSessionStorage("whatSelected", [])
  const whoOptions = allTaxonomyTermRoles.nodes.map(term => { return { value: term.drupal_id, label: term.name } })
  const [whoSelected, setWhoSelected] = useSessionStorage("whoSelected", [])
  const whereOptions = allTaxonomyTermLocations.nodes.map(term => { return { value: term.drupal_id, label: term.name } })
  const [whereSelected, setWhereSelected] = useSessionStorage("whereSelected", [])
  const withOptions = allTaxonomyTermEngagement.nodes.map(term => { return { value: term.drupal_id, label: term.name } })
  const [withSelected, setWithSelected] = useSessionStorage("withSelected", [])

  //const [ searchMode, setSearchMode ] = useSessionStorage("searchMode", "LS")
  // const handleSearchButtonClick = () => {
  //   setSearchMode("LS")
  //   navigate("/open-schooling-materials")
  // }

  return (
    <>
      <div id="page-header" className="pane">
        <div className="pad-m">
          <HeadingUnderline title={ title } subtitle={ getParsedHtml(subtitle.processed) } classes={["center"]} />
        </div>

        { image && <img className="image-full offset-down" srcSet={ image.srcSet } src={ image.src } alt={image.alt} width={image.width} height={image.height} data-object-fit="cover" /> }
        { (introduction || link) && <div className="pad-m">
          { introduction && <div className="introduction">{ getParsedHtml(introduction.processed) }</div> }
          { link && !isHome && <div className="ctas"><ALink link={block.link} className="button button-go" /></div> }
        </div> }

        { isHome && <div className="pad-m">
          <div className="wwww">
            <div className="what">
              <What className="wwww-illustration" />
              <h3 className="center">{ t("SEARCH-WHAT") }</h3>
              <Select options={whatOptions} value={whatSelected} onChange={ value => { setWhatSelected(value) }} {...defaultSelectProps} placeholder={ t("SEARCH-WHAT_PLACEHOLDER") } />
            </div>
            <div className="who">
              <Who className="wwww-illustration" />
              <h3 className="center">{ t("SEARCH-WHO") }</h3>
              <Select options={whoOptions} value={whoSelected} onChange={ value => { setWhoSelected(value) }} {...defaultSelectProps} placeholder={ t("SEARCH-WHO_PLACEHOLDER") } />
            </div>
            <div className="where">
              <Where className="wwww-illustration" />
              <h3 className="center">{ t("SEARCH-WHERE") }</h3>
              <Select options={whereOptions} value={whereSelected} onChange={ value => { setWhereSelected(value) }} {...defaultSelectProps} placeholder={ t("SEARCH-WHERE_PLACEHOLDER") } />
            </div>
            <div className="with">
              <With className="wwww-illustration" />
              <h3 className="center">{ t("SEARCH-WITH") }</h3>
              <Select options={withOptions} value={withSelected} onChange={ value => { setWithSelected(value) }} {...defaultSelectProps} placeholder={ t("SEARCH-WITH_PLACEHOLDER") } />
            </div>
          </div>
          { link && isHome && <div className="ctas">
            {/* <button className="button button-go" onClick={ () => { handleSearchButtonClick() } }>{ link.title }</button> */}
            <ALink link={{ url: "/open-schooling-materials?searchMode=LS", title: block.link.title }} className="button button-go" />
          </div> }
        </div> }
      </div>

    </>
  )
}

export default HeaderBlock
