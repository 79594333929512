import React from "react"

import Row from "../row"
import HeadingUnderline from "../headingUnderline"

import TextBlock from "./text"


const TextsBlock = ({ block }) => {
  const { title } = block
  const text_blocks = block.relationships.text_blocks

  return (
    <Row classes={["pane", "pad-s"]}>
      <HeadingUnderline title={ title } titleWeight="h2" classes={["center"]} />
      <div className="flex-columns">
        { text_blocks.map(text_block => <TextBlock key={text_block.title} block={text_block} />)}
      </div>
    </Row>
  )
}

export default TextsBlock
