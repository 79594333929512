import React from "react"

import { getParsedHtml } from "../../util/format"

import ALink from "../alink"


const TextBlock = ({ block, classes }) => {
  const { id, title, body, link } = block

  return (
    <div key={ id } className={`${classes.join(" ")}`}>
      <h3>{ title }</h3>
      <div>{ getParsedHtml(body.processed) }</div>
      <div className="ctas start"><ALink link={ link } className="more" /></div>
    </div>
  )
}

TextBlock.defaultProps = {
  classes: []
}

export default TextBlock
