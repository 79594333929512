import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Seo from "../parts/seo"
import Layout from "../parts/layout"

import HeaderBlock from "../parts/blocks/header"
import BannerBlock from "../parts/blocks/banner"
import PhotosBlock from "../parts/blocks/photos"
import IconsBlock from "../parts/blocks/icons"
import TextsBlock from "../parts/blocks/texts"
import TextWithSidebarBlock from "../parts/blocks/textWithSidebar"


const Page = ({ data, pageContext }) => {
  const { title } = data.nodePage
  const isHome = pageContext.i18n.originalPath === "" || pageContext.i18n.originalPath === "/"
  const homeLinks = pageContext.i18n.languages.map( lang => {
    return {
      path: {
        alias: (lang === "en" ? "/" : ""),
        langcode: lang
      }
    }
  })

  const blocks = data.nodePage.relationships.page_blocks.map((block, i) => {

    switch(block.internal.type) {
      case "paragraph__page_header":
        return <HeaderBlock key={i} block={block} isHome={isHome} terms={pageContext.terms} />
      case "paragraph__page_banner":
        return <BannerBlock key={i} block={block} />
      case "paragraph__page_photos":
        return <PhotosBlock key={i} block={block} />
      case "paragraph__page_icons":
        return <IconsBlock key={i} block={block} />
      case "paragraph__page_texts":
        return <TextsBlock key={i} block={block} />
      case "paragraph__page_text_with_sidebar":
        return <TextWithSidebarBlock key={i} block={block} />

      default:
        return <div key={i}>unknown block: { block.internal.type }</div>
    }
  })


  //console.log("a", data.allNodePage.nodes)

  return (
    <Layout links={ (isHome ? homeLinks : data.allNodePage?.nodes) } currentNavItem={ isHome ? "home" : "about" }>
      <Seo title={ title } />
      { blocks }
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`

  fragment icon_block on paragraph__page_icon {
    id
    title
    body {
      processed
    }
    link {
      title
      url
    }
    icon {
      alt
    }
    internal {
      type
    }
    relationships {
      icon {
        styles
        uri {
          url
        }
        filemime
      }
    }
  }

  fragment photo_block on paragraph__page_photo {
    id
    title
    label
    body {
      processed
    }
    link {
      title
      url
    }
    photo {
      alt
      width
      height
    }
    internal {
      type
    }
    relationships {
      photo {
        filemime
        styles
        uri {
          url
        }
      }
    }
  }

  fragment text_block on paragraph__page_text {
    id
    title
    body {
      processed
    }
    internal {
      type
    }
    link {
      url
      title
    }
  }

  query($language: String!, $gatsbyNodeId: String!, $drupalNodeId: String!) {
    locales: allLocale(filter: {language: {in: [$language, "pt", "en"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNodePage( filter: {drupal_id: {eq: $drupalNodeId}} ) {
      nodes {
        path {
          alias
          langcode
        }
      }
    }
    nodePage(id: {eq: $gatsbyNodeId}) {
      id
      title
      relationships {
        page_blocks {
          ... on paragraph__page_banner {
            id
            internal {
              type
            }
            title
            subtitle {
              processed
            }
            link {
              title
              url
            }
            relationships {
              background_image {
                styles
              }
            }
            background_image {
              alt
              width
              height
            }
          }
          ... on paragraph__page_header {
            id
            internal {
              type
            }
            title
            subtitle {
              processed
            }
            introduction {
              processed
            }
            link {
              title
              url
            }
            background_image {
              alt
              width
              height
            }
            relationships {
              background_image {
                styles
              }
            }
          }
          ... on paragraph__page_icons {
            id
            internal {
              type
            }
            title
            relationships {
              icon_blocks {
                ...icon_block
              }
            }
          }
          ... on paragraph__page_photos {
            id
            internal {
              type
            }
            title
            relationships {
              photo_blocks {
                ...photo_block
              }
            }
          }
          ... on paragraph__page_texts {
            id
            title
            internal {
              type
            }
            relationships {
              text_blocks {
                ...text_block
              }
            }
          }
          ... on paragraph__page_text_with_sidebar {
            id
            internal {
              type
            }
            body {
              processed
            }
            relationships {
              sidebar_blocks {
                ... on paragraph__page_icon {
                  ...icon_block
                }
                ... on paragraph__page_photo {
                  ...photo_block
                }
                ... on paragraph__page_text {
                  ...text_block
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Page
