import React from "react"

import Row from "../row"

import PhotoBlock from "./photo"
import IconBlock from "./icon"
import TextBlock from "./text"

import { getParsedHtml } from "../../util/format"


const TextWithSidebarBlock = ({ block }) => {
  const sidebar_blocks = block.relationships.sidebar_blocks.map((block, i) => {

    switch(block.internal.type) {
      case "paragraph__page_photo":
        return <PhotoBlock key={i} block={block} />
      case "paragraph__page_icon":
        return <IconBlock key={i} block={block} />
      case "paragraph__page_text":
        return <TextBlock key={i} block={block} classes={["pane pad-s"]} />

      default:
        return <div key={i}>unknown block: { block.internal.type }</div>
    }
  })


  return (
    <Row>
      <div className="text-with-sidebar">
        <div className="pane pad-m">
          { getParsedHtml(block.body.processed) }
        </div>

        <div className="text-sidebar">
          { sidebar_blocks }
        </div>
      </div>
    </Row>
  )
}

export default TextWithSidebarBlock
