import React, { useContext } from "react"

import { Link, I18nextContext, useI18next } from "gatsby-plugin-react-i18next"
import { getLangPrefixFromUrl } from "../util"

const ALink = ({ link, className }) => {
  const i18n = useContext(I18nextContext)
  const { t } = useI18next()

  if(!link) return null

  const title = link.title ? link.title : t("BUTTONS-READ_MORE")

  if(link.url.indexOf("http") === -1) {
    const [lang, path] = getLangPrefixFromUrl(link.url, i18n.languages)
    return <Link className={ className } to={ path } language={ lang }>{ title }</Link>
  } else {
    return <a className={ className } href={ link.url } target="_blank" rel="noreferrer noopener">{ title }</a>
  }
}

export default ALink
