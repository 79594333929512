import React from "react"

import { getParsedHtml } from "../../util/format"

import Row from "../row"
import ALink from "../alink"
import HeadingUnderline from "../headingUnderline"


const BannerBlock = ({ block }) => {
  const { title, link } = block
  const subtitle = block.subtitle.processed

  let image
  if(block.relationships.background_image) {
    const srcSet = []
    if(block.relationships.background_image.styles.large) srcSet.push(`${block.relationships.background_image.styles.large} 1280w`)
    if(block.relationships.background_image.styles.medium) srcSet.push(`${block.relationships.background_image.styles.medium} 640w`)
    if(block.relationships.background_image.styles.small) srcSet.push(`${block.relationships.background_image.styles.small} 360w`)

    image = {
      ...block.background_image,
      src: block.relationships.background_image.styles.large,
      srcSet: srcSet.join(", ")
    }
  }

  return (
    <Row>
      <div className="pane">
        { image && <img className="image-full offset-down" srcSet={ image.srcSet } src={ image.src } alt={image.alt} width={image.width} height={image.height} data-object-fit="cover" /> }
        <div className="pad-l">
          <HeadingUnderline title={ title } titleWeight="h2" classes={["center"]} />
          <p className="introduction">{ getParsedHtml(subtitle) }</p>
          {link && <div className="ctas"><ALink link={link} className="button button-go" /></div> }
        </div>
      </div>
    </Row>
  )
}

export default BannerBlock
