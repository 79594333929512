import React from "react"

import { getParsedHtml } from "../../util/format"

import { baseUrl } from "../../settings"

import ALink from "../alink"


const IconBlock = ({ block }) => {
  const { id, title, link, body } = block
  const icon = block.relationships.icon
  const image = {
    alt: block.icon.alt,
    url: icon.filemime.indexOf("svg") === -1 ? icon.image_style_uri[1].medium : baseUrl+icon.uri.url
  }

  return (
    <div key={ id }>
      <img src={ image.url } alt={ image.alt } />
      <h3>{ title }</h3>
      <div>{ getParsedHtml(body.processed) }</div>
      <ALink link={ link } />
    </div>
  )
}

export default IconBlock
