import React from "react"

import Row from "../row"
import IconBlock from "./icon"


const IconsBlock = ({ block }) => {
  const { title } = block
  const icon_blocks = block.relationships.icon_blocks

  return (
    <Row classes={["pane", "pad-s"]}>
      <h2>{ title }</h2>
      <div className="flex-columns">
        { icon_blocks.map(icon_block => <IconBlock key={icon_block.title} block={icon_block} />)}
      </div>
    </Row>
  )
}

export default IconsBlock
